<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <div class="col-12">
                    <ValidationProvider
                        name="name"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="form.name">
                            </b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider
                        name="code"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('code')">
                            <b-form-input
                                type="text"
                                v-model="form.code"

                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>

                <div class="col-12">
                    <ValidationProvider
                        name="class"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('class')">
                            <b-form-select v-model="form.class" :options="options"></b-form-select>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider
                        name="type"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox
                                code="medicine_group_types"
                                v-model="form.type"
                            ></parameter-selectbox>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex">
            <b-button
                @click="updateForm"
                type="button"
                variant="primary"
                class="btn-lg mr-2"
                :disabled="formLoading"
            >
                {{ $t("save").toUpperCase() }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Component
    import ParameterSelectbox from "../../../components/interactive-fields/ParameterSelectbox.vue";
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import MedicinegroupService from "@/services/MedicinegroupService";

    export default {
        components: {
            ValidationProvider, ValidationObserver,
            ParameterSelectbox
        },
        data() {
            return {
                options: [

                    {value: 1, text: 1},
                    {value: 2, text: 2},
                    {value: 3, text: 3},
                    {value: 4, text: 4},
                    {value: 5, text: 5},
                    {value: 6, text: 6},
                ],
                formLoading: false,
                form: {
                    code: null,
                    name: null,
                    class: null,
                    type: null
                }
            }
        },
        props: {
            formId: {
                type: Number,
            }
        },
        watch: {
            formId: function (val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                MedicinegroupService.get(id)
                              .then((response) => {
                                  this.form = response.data.data;
                              })
                              .catch((error) => {
                                  if (error.data.message) {
                                      this.$toast.error(this.$t("api." + error.data.message));
                                  }
                              }).finally(() => {
                    this.formLoading = false;
                });
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    MedicinegroupService.update(this.formId, this.form)
                                  .then((response) => {
                                      this.$toast.success(this.$t("api." + response.data.message));
                                      this.$emit("updateFormSuccess")
                                  })
                                  .catch((error) => {
                                      this.showErrors(error, this.$refs.formModalValidate)
                                  }).finally(() => {
                        this.formLoading = false;
                    })
                }
            }
        }
    }
</script>
